/**
 * Middleware to differentiate between PRODUCT and CATEGORY pages before loading the page.
 * Note: if we can ever differentiate between the two types of pages, we can move this middleware to the pages themselves.
 *       i.e. /c/category.html and /p/product.html
 *
 * This middleware fetches catalog data based on the current route's full path and assigns
 * the fetched data and its page type to the route's meta object.
 *
 * @param {RouteLocationNormalized} to - The target route object being navigated to.
 * @returns {Promise<void>} - A promise that resolves when the middleware logic is complete.
 * @throws {Error} - Throws an error if the catalog data fetch fails.
 */
export default defineNuxtRouteMiddleware(async (to) => {
  const { $i18n } = useNuxtApp()
  const { locale } = $i18n
  const logger = useAppLogger('catalog.ts')
  const catalogQuery = { path: to.fullPath }
  const data = await $fetch('/api/catalog', { query: catalogQuery }).catch((e) => {
    throw showError({
      statusCode: e.statusCode,
      statusMessage: e.statusMessage,
      fatal: true,
    })
  })

  if (data?.redirect_code) {
    const redirectUrl = `/${locale.value}/${data?.relative_url}`
    if (redirectUrl !== to.fullPath) {
      logger.info('Redirecting to', redirectUrl, 'with redirect code: ', data?.redirect_code)
      return navigateTo(redirectUrl)
    }
  }

  to.meta.data = data
  to.meta.type = data?.pageType
})
